import { TFunction } from 'i18next';
import { RoleEnum } from '@enums';

export const getRoleOptions = (t: TFunction) => [{
  value: RoleEnum.ADMIN, label: t('role.administrator'),
}, {
  value: RoleEnum.STANDARD, label: t('role.standard'),
}, {
  value: RoleEnum.VIEWER, label: t('role.viewer'),
}];

export const getRoleLabels = (role: RoleEnum, t: TFunction) => {
  const roleLabels = {
    [RoleEnum.ADMIN]: t('role.administrator'),
    [RoleEnum.STANDARD]: t('role.standard'),
    [RoleEnum.VIEWER]: t('role.viewer'),
  };
  return roleLabels[role];
};

export const allowedExternalMenus = ['shoptimizer'];

export const defaultMenuItem = [{
  name: 'Home',
  slug: 'home',
  iframe: '/admin-console/home',
}, {
  name: 'Change Password',
  slug: 'change-password',
  iframe: '/admin-console/change-password',
  hide: true,
}];

// eslint-disable-next-line max-len
// export const developmentProjectUrl = 'http://localhost:3000?show_map=false&time_series_data_source=lead_score_dashboard_time_series&cross_sectional_data_source=lead_score_dashboard_cross_sectional';
// eslint-disable-next-line max-len
export const developmentProjectUrl = 'http://localhost:3000?a=global_tv_reach_by_channel&global_tv_time_series_reach_by_media=global_tv_reach_by_media&global_tv_singular_data_source=global_tv_impacted&connected_tv_singular_total_cost=connected_tv_total_cost&connected_tv_singular_by_channel_data_source=connected_tv_by_channel_raw&connected_tv_total_data_source=connected_tv_total_raw';
// eslint-disable-next-line max-len
// export const developmentProjectUrl = 'http://localhost:3000?training_bqml=model_training_cross_sectional&thresholds_bqml=model_training_thresholds_cross_sectional&feature_bqml=model_training_feature_cross_sectional';
// eslint-disable-next-line max-len
// export const developmentProjectUrl = 'http://localhost:3000?data_evaluation_cross_sectional=data_evaluation_cross_sectional&data_evaluation_model_cross_sectional=data_evaluation_model_cross_sectional&data_evaluation_time_series=data_evaluation_time_series';

export const googleLoginClientIdKey = 'GCID-rvANdoERGAUCkyaqUESi';
export const googleLoginClientId = '248314359656-01dihmuhb225ja17vte491a249geaaq0.apps.googleusercontent.com';
// export const googleLoginClientId = '248314359656-01dihmuhb225ja17vte491a249geaaq0.apps.googleusercontent.com';

export const drawerWidth = 280;
