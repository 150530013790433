import Toolbar from '@mui/material/Toolbar';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { ReactComponent as GaussLogo } from '@assets/logos/gauss-logo.svg';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as MenuIcon } from '@assets/icons/menu-icon.svg';
import { ReactComponent as BurgerIcon } from '@assets/icons/header-burger.svg';
import { ReactComponent as CloseIcon } from '@assets/icons/header-close.svg';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import LanguagePicker from '@components/UI/atoms/languagePicker';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import AppBar from '@mui/material/AppBar';
import { FC, MouseEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MainState } from '@redux/reducers';
import { logout } from '@redux/auth/actions';
import { useTranslation } from 'react-i18next';
import { SubMenuModel } from '@models/general';
import { HeaderProps } from '@components/layout/adminUser/header/header.types';
import { getFirstSubMenuItem } from '@utils';
import { palette } from '@gauss/common-react-components';
import { drawerWidth } from '@constants';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

const HeaderComponent: FC<HeaderProps> = ({
  routesData, sidebarVisible, setSidebarVisible,
}) => {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [selectedSubMenu, setSelectedSubMenu] = useState<SubMenuModel>();
  const userData = useSelector((mState: MainState) => mState.user.user.data);
  const menus = useSelector((state: MainState) => state.general.selectedMenu);
  const location = useLocation();
  const myTheme = useTheme();
  const isBreakpointMatch = useMediaQuery(myTheme.breakpoints.down('md'));

  useEffect(() => {
    const currentMenu = menus?.menu?.find((item) =>
      location.pathname.includes(item.slug));
    if (currentMenu) {
      console.log(currentMenu);
      setSelectedSubMenu(currentMenu);
    }
  }, [location.pathname, menus, selectedSubMenu]);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleOpenNavMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const logoutClickHandler = () => {
    handleCloseNavMenu();
    dispatch(logout());
    navigate('/login', {
      replace: true,
    });
  };

  const navigateTo = (menu: SubMenuModel) => {
    navigate(`/${getFirstSubMenuItem(menu).slug}`);
  };

  const initialFirstNameLetter = userData?.email.split('.')[0][0].toUpperCase();
  const initialLastNameLetter = userData?.email.split('.')[1][0].toUpperCase();

  return (
    <AppBar
      position='fixed'
      sx={ {
        background: (theme) => theme.palette.grey[50],
        zIndex: (theme) => theme.zIndex.drawer + 1,
        boxShadow: 'none',
      } }
    >
      <Toolbar
        disableGutters
        sx={ {
          flex: 1,
          minHeight: 80,
        } }
      >
        <Box sx={ {
          width: isBreakpointMatch ? '100%' : drawerWidth,
          backgroundColor: palette.grey[900],
          py: (theme) => theme.spacing(2.5),
          pl: (theme) => theme.spacing(3),
          alignItems: 'center',
          justifyContent: isBreakpointMatch ? 'space-between' : 'center',
          borderBottom: '1px solid white',
        } }
        >
          {
            isBreakpointMatch
              ? (
                <Box
                  sx={ {
                    display: 'flex',
                    justifyContent: 'space-between',
                  } }
                >
                  <Link to='/'>
                    <GaussLogo />
                  </Link>
                  <IconButton
                    onClick={ setSidebarVisible }
                  >
                    { !sidebarVisible ? <BurgerIcon /> : <CloseIcon /> }
                  </IconButton>
                </Box>
              )
              : (
                <Link to='/'>
                  <GaussLogo />
                </Link>
              )
          }

        </Box>
        { !isBreakpointMatch ? (
          <Box sx={ {
            flexGrow: 1,
            display: {
              xs: 'flex', md: 'none',
            },
          } }
          >
            <IconButton
              size='large'
              aria-label='account of current user'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={ handleOpenNavMenu }
              color='inherit'
            >
              <MenuIcon
                width={ 24 }
                height={ 24 }
                fill='white'
              />
            </IconButton>
            <Menu
              id='menu-appbar'
              anchorEl={ anchorElNav }
              anchorOrigin={ {
                vertical: 'bottom',
                horizontal: 'left',
              } }
              keepMounted
              transformOrigin={ {
                vertical: 'top',
                horizontal: 'left',
              } }
              open={ Boolean(anchorElNav) }
              onClose={ handleCloseNavMenu }
              sx={ {
                display: {
                  xs: 'block', md: 'none',
                },
              } }
            >
              { routesData?.filter((item) => !item.hide)?.map((page) => (
                <MenuItem
                  key={ page.slug }
                  onClick={ () => {
                    handleCloseNavMenu();
                    navigateTo(page);
                  } }
                >
                  <Typography textAlign='center'>
                    { t(`routes.${page.slug}`, page.name) }
                  </Typography>
                </MenuItem>
              )) }
              <MenuItem>
                <LanguagePicker />
              </MenuItem>
            </Menu>
          </Box>
        ) : null }
        { !isBreakpointMatch ? (
          <Box
            sx={ {
              flex: 1,
              display: 'flex',
              position: 'relative',
              alignItems: 'center',
              justifyContent: 'flex-end',
              my: (theme) => theme.spacing(2.5),
              mr: (theme) => theme.spacing(3),
            } }
          >
            { selectedSubMenu && (
            <Typography
              variant='h4'
              color={ palette.grey[700] }
              position='absolute'
              sx={ {
                left: 30,
              } }
            >
              { t(`${selectedSubMenu?.slug}`, selectedSubMenu?.name) }
            </Typography>
            ) }
            <Box
              sx={ {
                position: 'absolute',
                right: 100,
              } }
            >
              <LanguagePicker />
            </Box>
            { userData && (
            <Typography
              variant='h6Medium'
              sx={ {
                mr: 2,
                color: palette.gaussPrimary.gaussPink,
              } }
            >
              { initialFirstNameLetter }
              { '. ' }
              { initialLastNameLetter }
            </Typography>
            ) }
            <Tooltip title={ t('common.open-settings').toString() }>
              <IconButton
                onClick={ handleOpenUserMenu }
                sx={ {
                  padding: 0,
                } }
              >
                <Avatar
                  sx={ {
                    height: 40,
                  } }
                >
                  { userData?.email ? userData?.email[0].toUpperCase() : 'G' }
                </Avatar>
              </IconButton>
            </Tooltip>
            <Menu
              sx={ {
                mt: '45px',
              } }
              id='menu-appbar'
              anchorEl={ anchorElUser }
              anchorOrigin={ {
                vertical: 'top',
                horizontal: 'right',
              } }
              keepMounted
              transformOrigin={ {
                vertical: 'top',
                horizontal: 'right',
              } }
              open={ Boolean(anchorElUser) }
              onClose={ handleCloseUserMenu }
            >
              <MenuItem key='change-password' onClick={ () => navigate('/change-password') }>
                <Typography textAlign='center'>{ t('common.change-password') }</Typography>
              </MenuItem>
              <MenuItem key='logout' onClick={ logoutClickHandler }>
                <Typography textAlign='center'>{ t('common.logout') }</Typography>
              </MenuItem>
            </Menu>
          </Box>
        ) : null }
      </Toolbar>
    </AppBar>
  );
};

export default HeaderComponent;
