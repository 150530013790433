import { AnyAction } from 'redux';
import { UserAction } from './types';
import { GeneralReduxModel, ResponseStateType } from '../types';

export type AuthState = {
  authorization: GeneralReduxModel;
}

const INITIAL_STATE: AuthState = {
  authorization: {
    state: ResponseStateType.DEFAULT,
    message: '',
    data: undefined,
  },
};

export default (state = INITIAL_STATE, action: AnyAction = {
  type: '',
}): AuthState => {
  switch (action.type) {
    case UserAction.LOGIN_REQUEST:
      return {
        authorization: {
          state: ResponseStateType.LOADING,
          message: '',
          data: undefined,
        },
      };
    case UserAction.LOGIN_SUCCESS:
      return {
        authorization: {
          state: ResponseStateType.SUCCESS,
          message: '',
          data: {
            accessToken: action.payload.accessToken,
            refreshToken: action.payload.refreshToken || state.authorization.data.refreshToken,
          },
        },
      };
    case UserAction.LOGIN_ERROR:
      return {
        authorization: {
          state: ResponseStateType.ERROR,
          message: action.payload.message,
          data: undefined,
        },
      };
    case UserAction.LOGOUT:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
