import { FC, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserRequest } from '@redux/user/actions';
import { logout } from '@redux/auth/actions';
import { MainState } from '@redux/reducers';
import { ResponseStateType } from '@redux/types';
import AdminUserLayout from '@components/layout/adminUser';
import { useBackdrop } from '@gauss/common-react-components';
import { defaultMenuItem } from '@constants';

const PrivateRoutes: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const {
    show, hide,
  } = useBackdrop();

  const {
    state: fetchUserState,
    message: fetchUserMessage,
  } = useSelector((state: MainState) => state.user.user);

  useEffect(() => {
    dispatch(fetchUserRequest());
  }, [dispatch]);

  useEffect(() => {
    if (fetchUserState === ResponseStateType.LOADING) {
      show();
    } else {
      hide();
      if (fetchUserState === ResponseStateType.SUCCESS) {
        setIsLoggedIn(true);
      } else if (fetchUserState === ResponseStateType.ERROR) {
        dispatch(logout());
        setIsLoggedIn(false);
        navigate('/login', {
          replace: true,
        });
      }
    }
  }, [fetchUserState, dispatch, navigate, fetchUserMessage, show, hide]);

  useEffect(() => {
    if (location.pathname === '/') {
      navigate(`/${defaultMenuItem[0].slug}`);
    }
  }, [location.pathname, navigate]);

  if (isLoggedIn) {
    return <AdminUserLayout />;
  }

  return null;
};

export default PrivateRoutes;
