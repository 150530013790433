import { GeneralReduxModel } from '../types';
import { UserModel } from '../../models/user';

export enum UserAction {
  FETCH_USER_REQUEST = 'FETCH_USER_REQUEST',
  FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS',
  FETCH_USER_ERROR = 'FETCH_USER_ERROR',

  FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST',
  FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS',
  FETCH_USERS_ERROR = 'FETCH_USERS_ERROR',
  SET_SELECTED_USER = 'SET_SELECTED_USER',
  LOGOUT = 'LOGOUT'
}
export interface UserReducerType extends GeneralReduxModel {
  data?: UserModel;
}
