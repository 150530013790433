/* eslint-disable no-nested-ternary */
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { MainState } from '@redux/reducers';
import Box from '@mui/material/Box';
import SubMenuTabsComponent from '@components/UI/molecules/subMenuTabs/subMenuTabs.component';
import { SubMenuModel } from '@models/general';
import { StyledSubMenuWrapper } from './subMenu.styles';

// eslint-disable-next-line sonarjs/cognitive-complexity
const SubMenu = () => {
  const menus = useSelector((state: MainState) => state.general.selectedMenu);
  const location = useLocation();

  const [selectedSubMenu, setSelectedSubMenu] = useState<SubMenuModel>();
  // TODO: Move to context
  useEffect(() => {
    const handleMessage = (event: MessageEvent<any>) => {
      console.log('From Iframe SubMenu', event.data);
      try {
        if (typeof event.data === 'string') {
          const eventData = JSON.parse(event.data);
          if (eventData.operation === 'SHOW_BACKDROP') {
            console.log('SHOW_BACKDROP');
          } else if (eventData.operation === 'HIDE_BACKDROP') {
            console.log('HIDE_BACKDROP');
          }
        }
      } catch (error: any) {
        console.error(error.message);
      }
    };

    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, []);

  useEffect(() => {
    const currentMenu = menus?.menu?.find((item) =>
      location.pathname.includes(item.slug));
    if (currentMenu) {
      console.log(currentMenu);
      setSelectedSubMenu(currentMenu);
    }
  }, [location.pathname, menus, selectedSubMenu]);

  const subMenus = useMemo(() => selectedSubMenu?.menu?.find((item) =>
    location.pathname.includes(item.slug)), [selectedSubMenu, location.pathname]);

  return (
    <Box
      id='sub-menu-area'
    >
      { /* { secondLevelMenus } */ }
      { menus?.menu && selectedSubMenu && selectedSubMenu?.menu ? (
        <StyledSubMenuWrapper>
          <SubMenuTabsComponent subMenu={ subMenus || selectedSubMenu } />
        </StyledSubMenuWrapper>
      ) : null }
    </Box>
  );
};

export default SubMenu;

