import { drawerWidth } from '@constants';
import { palette } from '@gauss/common-react-components';
import { Drawer, ListItem, styled, Collapse } from '@mui/material';

export const StyledDrawer = styled(Drawer)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
  [theme.breakpoints.up('md')]: {
    width: drawerWidth,
  },
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    boxShadow: 'inset -1px 0px 0px #3E3E3E',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
    },
    boxSizing: 'border-box',
    backgroundColor: palette.grey[900],
  },
}));

export const StyledListItem = styled(ListItem)(({ theme }) => ({
  height: 48,
  paddingLeft: theme.spacing(3),
  display: 'flex',
  cursor: 'pointer',
  position: 'relative',
  '&.active': {
    background: theme.palette.gaussPrimary.gaussPink,
  },
}));

export const StyledNestedListItem = styled(ListItem)(({ theme }) => ({
  borderTop: '1px solid black',
  minHeight: 48,
  height: 'auto',
  paddingLeft: theme.spacing(3),
  paddingBottom: theme.spacing(2),
  paddingTop: theme.spacing(2),
  display: 'flex',
  position: 'relative',
  cursor: 'pointer',
  '&.active': {
    borderRight: `4px solid ${theme.palette.gaussPrimary.gaussPink}`,
  },
}));

export const StyledDoubleNestedListItem = styled(ListItem)(({ theme }) => ({
  borderTop: '1px solid black',
  height: 'auto',
  minHeight: 48,
  paddingLeft: theme.spacing(3),
  paddingBottom: theme.spacing(2),
  paddingTop: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  background: theme.palette.grey[700],
  '&.active': {
    borderRight: `4px solid ${theme.palette.gaussPrimary.gaussPink}`,
  },
}));

export const StyledCollapse = styled(Collapse)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  cursor: 'pointer',
  background: theme.palette.grey[800],
  padddingTop: 0,
  paddingBottom: 0,
}));
