import axios from 'axios';
import { ApiResponse, BaseResponse } from '@gauss/common-react-components/dist/types';
import { generateMenuSlugs } from '@utils';
import {
  LoginModel,
  UserModel,
} from '@models/user';
import { store } from '@redux';
import { MenuModel } from '@models/general';
import { loginSuccess, logout } from '@redux/auth/actions';
import { googleLoginClientIdKey } from '@constants';
import { setDialog } from '@redux/general/actions';

axios.defaults.headers.common['Content-Type'] = 'application/json';

const axiosInstance = axios.create({});

axiosInstance.interceptors.request.use(
  (request) => {
    if (request.headers) {
      request.headers.Authorization
        = `Bearer ${store.getState().auth.authorization.data?.accessToken}` || '';
    }

    return request;
  },
  (error) => Promise.reject(error.response),
);

const refreshAccessToken = async () => {
  try {
    const { data } = await axios.post<{access: string;}>(
      '/portal/api/v1/users/token/refresh/',
      {
        refresh: store.getState().auth.authorization.data?.refreshToken || '',
      },
    );

    return {
      success: true,
      accessToken: data.access,
    };
  } catch (error: any) {
    return {
      success: false,
      message: error?.response?.data?.message,
    };
  }
};

export const pingStrategy = async () => {
  try {
    const { data } = await axios.get<{time_period: number; servers: Array<string>;}>('/portal/api/v1/ping-strategy/');
    return {
      status: true,
      data,
    };
  } catch (err: any) {
    return {
      status: false, message: err.message,
    };
  }
};

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    // eslint-disable-next-line sonarjs/no-collapsible-if
    if (error.response.status === 401 && !originalRequest._retry) {
      /* eslint no-underscore-dangle: 0 */
      originalRequest._retry = true;
      if (store.getState().auth.authorization.data?.refreshToken) {
        const res = await refreshAccessToken();
        if (res.success && res.accessToken) {
          store.dispatch(loginSuccess({
            accessToken: res.accessToken,
          }));
          return axiosInstance(originalRequest);
        }
        store.dispatch(logout());
        window.location.reload();
        return Promise.reject(new Error(error?.message));
      }
      store.dispatch(logout());
      window.location.href = '/login';
      return Promise.reject(new Error(error?.message));
    }
    let message = error?.response?.data?.message || error?.response?.data?.detail || error?.message;
    if (typeof error?.response?.data === 'object') {
      message = Object.values(error?.response?.data).flatMap((item) => item);
    }
    store.dispatch(setDialog({
      show: true, description: message, label: 'Ok',
    }));
    return Promise.reject(new Error(message));
  },
);

export const login = async ({
  email, password,
}: {email: string; password: string;}): Promise<ApiResponse<LoginModel>> => {
  try {
    const { data } = await axios.post<{access: string; refresh: string;}>('/portal/api/v1/users/login/', {
      email, password,
    });
    return {
      status: true,
      data: {
        accessToken: data.access,
        refreshToken: data.refresh,
      },
    };
  } catch (e: any) {
    return {
      status: false, message: e.response?.data?.detail || e.message,
    };
  }
};

export const googleLogin = async ({ idToken }: {idToken: string;}): Promise<ApiResponse<LoginModel>> => {
  try {
    const { data } = await axios.post<{access: string; refresh: string; }>('/portal/api/v1/users/auth-google/', {
      id_token: idToken,
    });
    return {
      status: true,
      data: {
        accessToken: data.access,
        refreshToken: data.refresh,
      },
    };
  } catch (e: any) {
    return {
      status: false, message: e.response?.data?.detail || e.message,
    };
  }
};

export const fetchUser = async (): Promise<ApiResponse<UserModel>> => {
  try {
    const { data } = await axiosInstance.get<UserModel>('/portal/api/v1/current_user/');
    return {
      status: true, data,
    };
  } catch (e: any) {
    return {
      status: false, message: e.message,
    };
  }
};

export const fetchUsers = async (): Promise<ApiResponse<UserModel[]>> => {
  try {
    const { data } = await axiosInstance.get<UserModel[]>('/portal/api/v1/users/');
    return {
      status: true, data,
    };
  } catch (e: any) {
    return {
      status: false, message: e.message,
    };
  }
};

export const createUser = async (params: UserModel): Promise<BaseResponse> => {
  try {
    await axiosInstance.post('/portal/api/v1/users/register/', params);
    return {
      status: true,
    };
  } catch (e: any) {
    return {
      status: false, message: e.message,
    };
  }
};

export const updateUser = async (
  id: number,
  params: UserModel,
): Promise<BaseResponse> => {
  try {
    await axiosInstance.patch(`/portal/api/v1/users/modify_user/${id}/`, params);
    return {
      status: true,
    };
  } catch (e: any) {
    return {
      status: false, message: e.message,
    };
  }
};

export const deleteUser = async (userId: number): Promise<BaseResponse> => {
  try {
    await axiosInstance.delete(`/portal/api/v1/users/delete-user/${userId}`);
    return {
      status: true,
    };
  } catch (e: any) {
    return {
      status: false, message: e.message,
    };
  }
};

export const fetchMenus = async (): Promise<ApiResponse<MenuModel[]>> => {
  try {
    const { data } = await axiosInstance.get<{menu: MenuModel[];}>('/portal/api/v1/menus/');
    // TODO: Revert this change
    // const { data } = await axiosInstance.get<{menu: MenuModel[];}>('/menu.json', {
    //   headers: {
    //     isPublic: true,
    //   },
    // });
    return {
      status: true, data: data.menu.map(generateMenuSlugs),
    };
  } catch (e: any) {
    return {
      status: false, message: e.message,
    };
  }
};

export const fetchGoogleLoginClientId = async (): Promise<ApiResponse<string>> => {
  try {
    const { data } = await axios.get<string>(`/${googleLoginClientIdKey}`);
    return {
      status: true, data,
    };
  } catch (e: any) {
    return {
      status: false, message: e.message,
    };
  }
};

export const changePassword = async ({
  currentPassword,
  newPassword,
  newPasswordConfirm,
}: {currentPassword: string; newPassword: string; newPasswordConfirm: string;}): Promise<ApiResponse<boolean>> => {
  try {
    const { data } = await axiosInstance.post<boolean>('/portal/api/v1/users/change-password/', {
      current_password: currentPassword,
      new_password: newPassword,
      new_password_confirm: newPasswordConfirm,
    });
    return {
      status: true, data,
    };
  } catch (e: any) {
    return {
      status: false, message: e.message,
    };
  }
};
