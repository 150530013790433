import { UserModel } from 'src/models/user';
import { UserAction } from './types';
import { PayloadAction } from '../utils';

export const fetchUserRequest = (): PayloadAction => ({
  type: UserAction.FETCH_USER_REQUEST,
});
export const fetchUserSuccess = (data: UserModel): PayloadAction => ({
  type: UserAction.FETCH_USER_SUCCESS,
  payload: data,
});
export const fetchUserError = (data: Error): PayloadAction => ({
  type: UserAction.FETCH_USER_ERROR,
  payload: data,
});

export const fetchUsersRequest = (): PayloadAction => ({
  type: UserAction.FETCH_USERS_REQUEST,
});
export const fetchUsersSuccess = (data: UserModel[]): PayloadAction => ({
  type: UserAction.FETCH_USERS_SUCCESS,
  payload: data,
});
export const fetchUsersError = (data: Error): PayloadAction => ({
  type: UserAction.FETCH_USERS_ERROR,
  payload: data,
});

export const setSelectedUser = (user: UserModel): PayloadAction => ({
  type: UserAction.SET_SELECTED_USER,
  payload: user,
});
