import { languages, palette, Select } from '@gauss/common-react-components';
import { useTranslation } from 'react-i18next';
import { useState, FC } from 'react';
import { Box, Typography, IconButton } from '@mui/material';

interface Props {
  bottom?: boolean;
}

const LanguagePicker: FC<Props> = ({ bottom }) => {
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language.split('-')?.[0]);

  return (
    bottom
      ? (
        <Box
          sx={ {
            display: 'flex',
            flexDirection: 'row',
          } }
        >
          {
            languages.map((language) => (
              <IconButton
                onClick={ () => {
                  i18n.changeLanguage(language.locale);
                  setSelectedLanguage(language.locale);
                } }
              >
                <Typography
                  sx={ {
                    color: selectedLanguage === language.locale ? palette.gaussPrimary.gaussPink : 'white',
                    mx: 1,
                  } }
                >
                  { language.label.slice(0, 3).toUpperCase() }
                </Typography>
              </IconButton>
            ))
          }
        </Box>
      )
      : (
        <Select
          value={ selectedLanguage }
          variant='standard'
          onChange={ (val) => {
            i18n.changeLanguage(val as string);
            setSelectedLanguage(val as string);
          } }
          options={ languages.map((language) => ({
            value: language.locale.slice(0, 3), label: language.label.slice(0, 3).toUpperCase(),
          })) }
          SelectDisplayProps={ {
            style: {
              padding: 0,
            },
          } }
          sx={ {
            minWidth: 80,
            width: '80px',
          } }
        />
      )
  );
};

export default LanguagePicker;
