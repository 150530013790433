export enum UserAction {
  LOGIN_REQUEST = 'LOGIN_REQUEST',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_ERROR = 'LOGIN_ERROR',
  GOOGLE_LOGIN_REQUEST = 'GOOGLE_LOGIN_REQUEST',
  // GOOGLE_LOGIN_SUCCESS = 'GOOGLE_LOGIN_SUCCESS',
  // GOOGLE_LOGIN_ERROR = 'GOOGLE_LOGIN_ERROR',
  LOGOUT = 'LOGOUT'
}

export default {
  UserAction,
};
