import {
  takeEvery,
  ForkEffect,
  call,
  put,
  CallEffect,
  PutEffect,
} from 'redux-saga/effects';

import { ApiResponse } from '@gauss/common-react-components/dist/types';
import { googleLogin, login } from '@api';
import { LoginModel } from '@models/user';
import { UserAction } from './types';
import { PayloadAction } from '../utils';
import { loginSuccess, loginError, logout } from './actions';

function* loginUserRequest(action: PayloadAction): Generator<
  CallEffect<ApiResponse<LoginModel>> | PutEffect,
  void,
  ApiResponse<LoginModel>
> {
  try {
    const userData: ApiResponse<LoginModel> = yield call((payload) => login(payload), action.payload);
    if (userData.status) {
      yield put(loginSuccess(userData.data!));
    } else {
      yield put(loginError(new Error(userData.message)));
    }
  } catch (error: any) {
    yield put(loginError(new Error(error.response?.data?.detail || error.message)));
    // yield put(loginError(new Error(e.message)));
    yield put(logout());
  }
}

function* googleLoginUserRequest(action: PayloadAction): Generator<
  CallEffect<ApiResponse<LoginModel>> | PutEffect,
  void,
  ApiResponse<LoginModel>
  > {
  try {
    const userData: ApiResponse<LoginModel> = yield call((payload) => googleLogin(payload), action.payload);
    if (userData.status) {
      yield put(loginSuccess(userData.data!));
    } else {
      yield put(loginError(new Error(userData.message)));
    }
  } catch (e: any) {
    yield put(loginError(new Error(e.response?.data?.detail || e.message)));
    yield put(logout());
  }
}

export default function* authSaga(): Generator<ForkEffect<never>, void> {
  yield takeEvery(UserAction.LOGIN_REQUEST, loginUserRequest);
  yield takeEvery(UserAction.GOOGLE_LOGIN_REQUEST, googleLoginUserRequest);
}
