import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { FC, useMemo } from 'react';
import Typography from '@mui/material/Typography';
import { SubMenuTabsProps } from '@components/UI/molecules/subMenuTabs/subMenuTabs.types';
import { TabWithRouterType } from '@gauss/common-react-components/dist/components/TabsWithRouter';
import { useLocation, useNavigate } from 'react-router-dom';
import { getFirstSubMenuItem } from '@utils';
import { palette } from '@gauss/common-react-components';
import { StyledSubMenuList, StyledSubMenu, SecondStyledSubMenu } from './subMenuTabs.styles';

const SubMenuTabsComponent: FC<SubMenuTabsProps> = ({
  subMenu, secondLevel, withBorder = true,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const selectedSubMenu = useMemo(
    () => subMenu.menu?.find((item) => location.pathname.includes(item.slug)),
    [location.pathname, subMenu.menu],
  );

  const tabs: TabWithRouterType[] = subMenu.menu?.map((item) => ({
    route: getFirstSubMenuItem(item).slug,
    label: t(`${item.name}`),
    isActive: location.pathname.includes(item.slug),
  })) || [];

  if (!subMenu) {
    return null;
  }

  return (
    <Box
      pt={ tabs.length ? 3 : 0 }
      sx={ (secondLevel && withBorder) || (!secondLevel && !tabs.length) ? {
        border: `1px solid ${palette.grey[300]}`,
        borderBottom: 0,
      } : undefined }
    >
      <StyledSubMenuList sx={ secondLevel ? {
        marginLeft: 2,
        marginRight: 2,
      } : undefined }
      >
        { tabs.map((tab) => {
          const IconComponent = tab?.icon;
          return (
            secondLevel
              ? (
                <SecondStyledSubMenu
                  className={ tab.isActive || location.pathname.includes(tab.route) ? 'active' : '' }
                  key={ tab.label }
                  onClick={ () => navigate(tab.route) }
                >
                  { IconComponent && <IconComponent /> }
                  <Typography>{ tab.label }</Typography>
                </SecondStyledSubMenu>
              )
              : (
                <StyledSubMenu
                  className={ tab.isActive || location.pathname.includes(tab.route) ? 'active' : '' }
                  key={ tab.label }
                  onClick={ () => navigate(tab.route) }
                >
                  { IconComponent && <IconComponent /> }
                  <Typography>{ tab.label }</Typography>
                </StyledSubMenu>
              )
          );
        }) }
      </StyledSubMenuList>
      { selectedSubMenu && (
        <SubMenuTabsComponent subMenu={ selectedSubMenu } secondLevel withBorder={ !secondLevel } />
      ) }
    </Box>
  );
};

export default SubMenuTabsComponent;
