import { IOkDialog, MenuModel, SubMenuModel } from '@models/general';
import { GeneralAction } from './types';
import { PayloadAction } from '../utils';

export const fetchMenusRequest = (): PayloadAction => ({
  type: GeneralAction.FETCH_MENUS_REQUEST,
});
export const fetchMenusSuccess = (data: MenuModel[]): PayloadAction => ({
  type: GeneralAction.FETCH_MENUS_SUCCESS,
  payload: data,
});
export const fetchMenusError = (data: Error): PayloadAction => ({
  type: GeneralAction.FETCH_MENUS_ERROR,
  payload: data,
});
export const setSelectedMenu = (data: SubMenuModel): PayloadAction => ({
  type: GeneralAction.SET_SELECTED_MENU,
  payload: data,
});
export const fetchGoogleLoginClientIdRequest = (): PayloadAction => ({
  type: GeneralAction.FETCH_GOOGLE_LOGIN_CLIENT_ID_REQUEST,
});
export const fetchGoogleLoginClientIdSuccess = (data: string): PayloadAction => ({
  type: GeneralAction.FETCH_GOOGLE_LOGIN_CLIENT_ID_SUCCESS,
  payload: data,
});
export const fetchGoogleLoginClientIdError = (data: Error): PayloadAction => ({
  type: GeneralAction.FETCH_GOOGLE_LOGIN_CLIENT_ID_ERROR,
  payload: data,
});
export const setDialog = (data: IOkDialog) => ({
  type: GeneralAction.SET_DIALOG,
  payload: data,
});
