import { MenuModel, SubMenuModel } from '@models/general';
import { RouteObject } from 'react-router/lib/router';
import { getComponentByPath } from '@routes';
import DynamicIFrameRenderer from '@components/pages/adminUser/dynamicIFrameRenderer';

export const isUrlSubdomain = (urlString: string) => {
  let url;

  try {
    url = new URL(urlString);
  } catch (_) {
    return false;
  }
  if (url.protocol !== 'http:' && url.protocol !== 'https:') {
    return false;
  }
  const currentHost = window.location.hostname;
  const urlHost = url.hostname;
  console.log(urlHost, currentHost, urlHost.includes(currentHost));
  return urlHost.includes(currentHost);
};

export const transformTextToSnakeCase = (str: string) => str.replace(/\W+/g, ' ')
  .toLowerCase()
  .split(/ |\B(?=[A-Z])/)
  .map((word) => word.toLowerCase())
  .join('_');

export const generateMenuSlugs = (menu: MenuModel) => {
  const menuObject = {
    ...menu,
  };

  const generateSubMenuSlugs = (subMenus: SubMenuModel[], prevSlug?: string): SubMenuModel[] => subMenus.map((subMenu) => {
    const slug = transformTextToSnakeCase(prevSlug ? `${prevSlug}_${subMenu.name}` : subMenu.name);
    return {
      ...subMenu,
      menu: subMenu.menu ? generateSubMenuSlugs(subMenu.menu, slug) : subMenu.menu,
      slug,
    };
  });

  if (menuObject.name) {
    menuObject.slug = transformTextToSnakeCase(`${menuObject.name}`);
  }

  return {
    ...menuObject,
    menu: generateSubMenuSlugs(menuObject.menu),
  };
};

export const getFirstSubMenuItem = (subMenu: SubMenuModel): SubMenuModel => {
  if (!(subMenu.menu && subMenu.menu.length > 0)) {
    return subMenu;
  }
  return getFirstSubMenuItem(subMenu.menu[0]);
};

export const generateRoutesObjects = (routesData: SubMenuModel[], iframeEl: any): RouteObject[] => routesData.flatMap((route) =>
  (route.menu ? generateRoutesObjects(route.menu, iframeEl) : [{
    path: `/${route.slug}`,
    element: getComponentByPath(route.iframe)
      || <DynamicIFrameRenderer ref={ iframeEl } iframeUrl={ route.iframe || '' } />,
  }]));

export const normalizeString = (str: string) => str.replace('\r\n', '').trim();
