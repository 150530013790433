import { persistCombineReducers, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { PersistConfig } from 'redux-persist/es/types';
import { UserState, userReducer } from './user';
import { AuthState, authReducer } from './auth';
import { generalReducer, GeneralState } from './general';

const persistConfig: PersistConfig<MainState> = {
  key: 'primary',
  storage,
  whitelist: ['auth'],
};

const authPersistConfig: PersistConfig<AuthState> = {
  key: 'auth',
  storage,
};

export type MainState = {
  auth: AuthState & PersistPartial;
  user: UserState;
  general: GeneralState;
}

export default persistCombineReducers<MainState>(persistConfig, {
  auth: persistReducer(authPersistConfig, authReducer),
  user: userReducer,
  general: generalReducer,
});
