export enum RoleEnum {
  ADMIN = 'administrator',
  VIEWER = 'viewer',
  STANDARD = 'standard'
}

export enum OperationsEnum {
  ADD = 'ADD',
  EDIT = 'EDIT'
}
