import {
  ReactElement, Suspense, useEffect, useMemo, useRef, useState,
} from 'react';
import { useLocation, useRoutes } from 'react-router-dom';
import Box from '@mui/material/Box';
import { CircularProgress, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useBackdrop } from '@gauss/common-react-components';
import { MainState } from '@redux/reducers';
import { fetchMenusRequest, setSelectedMenu } from '@redux/general/actions';
import { SubMenuModel } from '@models/general';
import { ResponseStateType } from '@redux/types';
import { defaultMenuItem } from '@constants';
import Header from '@components/layout/adminUser/header/header.component';
import Sidebar from '@components/layout/adminUser/sidebar/sidebar.component';
import { generateRoutesObjects } from '@utils';
import SubMenu from '../../UI/molecules/subMenu';

const AdminUserLayout = (): ReactElement => {
  const {
    state: menuState, data: menusData,
  } = useSelector((gState: MainState) => gState.general.menus);
  const iframeEl = useRef<HTMLIFrameElement>(null);

  const myTheme = useTheme();
  const isBreakpointMatch = useMediaQuery(myTheme.breakpoints.down('md'));

  console.log(isBreakpointMatch);

  const [sidebarVisible, setSidebarVisible] = useState(true);

  const routesData: SubMenuModel[] = useMemo(() => {
    const lRoutesData: SubMenuModel[] = [...defaultMenuItem];
    if (menusData && menusData[0]) {
      lRoutesData.push(...menusData[0].menu);
    }
    return lRoutesData;
  }, [menusData]);

  const routes = useRoutes(generateRoutesObjects(routesData, iframeEl));

  const dispatch = useDispatch();
  const location = useLocation();
  const {
    show, hide,
  } = useBackdrop();

  useEffect(() => {
    dispatch(fetchMenusRequest());
  }, [dispatch]);

  useEffect(() => {
    if (menuState === ResponseStateType.LOADING) {
      show();
    } else {
      hide();
    }
  }, [hide, menuState, show]);

  useEffect(() => {
    const selectedMenu = routesData.find((menu) => {
      if (menu.menu) {
        return !!menu.menu.find((subMenu) => location.pathname.includes(subMenu.slug));
      }
      return location.pathname.includes(menu.slug);
    });
    if (selectedMenu) {
      dispatch(setSelectedMenu(selectedMenu));
    }
  }, [dispatch, location.pathname, routesData]);

  const sidebarVisibilityHandler = () => {
    setSidebarVisible((prev) => !prev);
  };

  useEffect(() => {
    if (!isBreakpointMatch) {
      setSidebarVisible(true);
    }
  }, [isBreakpointMatch]);

  return (
    <Box
      component='main'
      sx={ {
        flexGrow: 1,
        marginLeft: {
          xs: 0, md: '280px',
        },
        marginTop: 10,
      } }
    >
      <Header routesData={ routesData } sidebarVisible={ sidebarVisible } setSidebarVisible={ sidebarVisibilityHandler } />
      { sidebarVisible ? <Sidebar routesData={ routesData } /> : null }
      <SubMenu />
      <Suspense
        fallback={ (
          <Box
            className='loading-wrapper'
          >
            <CircularProgress color='secondary' />
          </Box>
        ) }
      >
        { routes }
      </Suspense>
    </Box>
  );
};

export default AdminUserLayout;
