import { UserAction } from './types';
import { PayloadAction } from '../utils';

export type LoginRequestParams = {
  email: string;
  password: string;
}

export type LoginSuccessParams = {
  accessToken: string;
}

export const loginRequest = (data: LoginRequestParams): PayloadAction => ({
  type: UserAction.LOGIN_REQUEST,
  payload: data,
});

export const loginSuccess = (data: LoginSuccessParams): PayloadAction => ({
  type: UserAction.LOGIN_SUCCESS,
  payload: data,
});

export const loginError = (error: Error): PayloadAction => ({
  type: UserAction.LOGIN_ERROR,
  payload: error,
});

export const logout = (): PayloadAction => ({
  type: UserAction.LOGOUT,
});

export const googleLoginRequest = ({ idToken }: { idToken: string; }): PayloadAction => ({
  type: UserAction.GOOGLE_LOGIN_REQUEST,
  payload: {
    idToken,
  },
});

/*
export const googleLoginSuccess = (data: LoginSuccessParams): PayloadAction => ({
  type: UserAction.GOOGLE_LOGIN_SUCCESS,
  payload: data,
});

export const googleLoginError = (error: Error): PayloadAction => ({
  type: UserAction.GOOGLE_LOGIN_ERROR,
  payload: error,
});
*/

