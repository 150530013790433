import { AnyAction } from 'redux';
import { UserAction, UserReducerType } from './types';
import { GeneralReduxModel, ResponseStateType } from '../types';
import { UserModel } from '../../models/user';

export type UserState = {
  user: UserReducerType;
  users: GeneralReduxModel;
  selectedUser?: UserModel;
}

const INITIAL_STATE: UserState = {
  user: {
    state: ResponseStateType.DEFAULT,
    message: '',
    data: undefined,
  },
  users: {
    state: ResponseStateType.DEFAULT,
    message: '',
    data: [],
  },
  selectedUser: undefined,
};

export default (state = INITIAL_STATE, action: AnyAction = {
  type: '',
}): UserState => {
  switch (action.type) {
    case UserAction.FETCH_USER_REQUEST:
      return {
        ...state,
        user: {
          state: ResponseStateType.LOADING,
          message: '',
          data: undefined,
        },
      };
    case UserAction.FETCH_USER_SUCCESS:
      return {
        ...state,
        user: {
          state: ResponseStateType.SUCCESS,
          message: '',
          data: action.payload,
        },
      };
    case UserAction.FETCH_USER_ERROR:
      return {
        ...state,
        user: {
          state: ResponseStateType.ERROR,
          message: action.payload?.message,
          error: action.payload,
        },
      };

    case UserAction.FETCH_USERS_REQUEST:
      return {
        ...state,
        users: {
          state: ResponseStateType.LOADING,
          message: '',
          data: [],
        },
      };
    case UserAction.FETCH_USERS_SUCCESS:
      return {
        ...state,
        users: {
          state: ResponseStateType.SUCCESS,
          message: '',
          data: action.payload,
        },
      };
    case UserAction.FETCH_USERS_ERROR:
      return {
        ...state,
        users: {
          state: ResponseStateType.ERROR,
          message: action.payload?.message,
          error: action.payload,
          data: [],
        },
      };
    case UserAction.SET_SELECTED_USER:
      return {
        ...state,
        selectedUser: action.payload,
      };
    case UserAction.LOGOUT:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
