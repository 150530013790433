import {
  takeEvery,
  ForkEffect,
  call,
  put,
  CallEffect,
  PutEffect,
} from 'redux-saga/effects';
import { fetchGoogleLoginClientId, fetchMenus } from 'src/api';
import { ApiResponse } from '@gauss/common-react-components/dist/types';
import { MenuModel } from '@models/general';
import { GeneralAction } from './types';
import { PayloadAction } from '../utils';
import {
  fetchMenusSuccess,
  fetchMenusError,
  fetchGoogleLoginClientIdSuccess,
  fetchGoogleLoginClientIdError,
} from './actions';

function* fetchMenusRequest(action: PayloadAction):
  Generator<CallEffect<ApiResponse<MenuModel[]>> | PutEffect, void, ApiResponse<MenuModel[]>> {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const menuData: ApiResponse<MenuModel[]> = yield call((payload) => fetchMenus(), action.payload);
    if (menuData.status) {
      yield put(fetchMenusSuccess(menuData.data || []));
    } else {
      yield put(fetchMenusError(new Error(menuData.message)));
    }
  } catch (e: any) {
    yield put(fetchMenusError(new Error(e.response?.error?.message)));
  }
}

function* fetchGoogleLoginClientIdRequest(action: PayloadAction):
  Generator<CallEffect<ApiResponse<string>> | PutEffect, void, ApiResponse<string>> {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const res: ApiResponse<string> = yield call((payload) => fetchGoogleLoginClientId(), action.payload);
    if (res.status) {
      yield put(fetchGoogleLoginClientIdSuccess(res.data || ''));
    } else {
      yield put(fetchGoogleLoginClientIdError(new Error('Unknown Error')));
    }
  } catch (e: any) {
    yield put(fetchGoogleLoginClientIdError(e.message));
  }
}

export default function* userSaga(): Generator<ForkEffect<never>, void> {
  yield takeEvery(GeneralAction.FETCH_MENUS_REQUEST, fetchMenusRequest);
  yield takeEvery(GeneralAction.FETCH_GOOGLE_LOGIN_CLIENT_ID_REQUEST, fetchGoogleLoginClientIdRequest);
}
