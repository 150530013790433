import { forwardRef, MutableRefObject, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { isValidHttpUrl } from '@gauss/common-react-components/dist/utils';
import { useBackdrop } from '@gauss/common-react-components/dist/context';
import { useTranslation } from 'react-i18next';
import { MainState } from '@redux/reducers';
import { loginSuccess, logout } from '@redux/auth/actions';
import { allowedExternalMenus, developmentProjectUrl } from '@constants';
import { isUrlSubdomain } from '@utils';
import { envVariables } from 'src/env';
import { palette } from '@gauss/common-react-components';

interface ComponentProps {
  iframeUrl: string;
}

const headerHeightWithMargin = 84 + 24;

// eslint-disable-next-line sonarjs/cognitive-complexity
const DynamicIFrameRenderer = forwardRef<HTMLIFrameElement, ComponentProps>(({ iframeUrl }, iframeRef) => {
  const [subMenuHeight, setSubMenuHeight] = useState(0);
  const { data: authData } = useSelector((appState: MainState) => appState.auth.authorization);
  const location = useLocation();

  const menus = useSelector((state: MainState) => state.general.selectedMenu);
  const isLevel2Menu = !!menus?.menu?.find((p1) => location.pathname
    .includes(p1.slug))?.menu?.find((p2) => location.pathname
    .includes(p2.slug))?.menu?.find((p3) => location.pathname
    .includes(p3.slug))?.menu;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    show, hide,
  } = useBackdrop();

  const { i18n } = useTranslation();

  useEffect(() => {
    if (iframeUrl) {
      show();
    }
  }, [show, iframeUrl]);

  let iframeEl: MutableRefObject<HTMLIFrameElement | null> | null = null;
  if (iframeRef != null && typeof iframeRef !== 'function') {
    iframeEl = iframeRef;
  }

  useEffect(() => {
    iframeEl?.current?.contentWindow?.postMessage(JSON.stringify({
      operation: 'CHANGE_LANGUAGE', language: i18n.language,
    }), '*');
  }, [i18n.language, iframeEl]);

  // TODO: Move to context
  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(() => {
    const handleMessage = (event: MessageEvent<any>) => {
      console.info('From Iframe DynamicIframeRenderer', event.data);
      if (!isValidHttpUrl(iframeUrl)
        || !!allowedExternalMenus.find((menu) => iframeUrl.includes(menu))
        || isUrlSubdomain(iframeUrl)
        || envVariables.NODE_ENV === 'development') {
        try {
          if (typeof event.data === 'string') {
            const eventData = JSON.parse(event.data);
            if (eventData.operation === 'REFRESH_TOKEN' && eventData?.accessToken) {
              dispatch(loginSuccess({
                accessToken: eventData.accessToken,
              }));
            } else if (eventData.operation === 'LOGOUT') {
              dispatch(logout());
              navigate('/login', {
                replace: true,
              });
            } else if (eventData.operation === 'GET_TOKEN') {
              iframeEl?.current?.contentWindow?.postMessage(JSON.stringify({
                operation: 'READ_TOKENS', ...authData,
              }), '*');
            }
          }
        } catch (error: any) {
          console.error(error.message);
          dispatch(logout());
          navigate('/login', {
            replace: true,
          });
        }
      }
    };

    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, [authData, dispatch, iframeEl, iframeUrl, navigate]);

  useEffect(() => {
    setTimeout(() => {
      const clientHeight = document.getElementById('sub-menu-area')?.offsetHeight;
      console.info('clientHeight', clientHeight, menus);
      setSubMenuHeight(clientHeight || 0);
    }, 0);
  }, [menus]);

  let formattedIframeUrl = envVariables.NODE_ENV === 'development' ? developmentProjectUrl : iframeUrl;
  if (formattedIframeUrl.includes('?')) {
    formattedIframeUrl = `${formattedIframeUrl}&parent_base_url=${window.location.origin}`;
  } else {
    formattedIframeUrl = `${formattedIframeUrl}?parent_base_url=${window.location.origin}`;
  }

  return (
    <Box sx={ isLevel2Menu ? {
      position: 'relative',
      border: `1px solid ${palette.grey[300]}`,
      borderTop: 0,
      height: `calc(100vh - ${isLevel2Menu ? (subMenuHeight + headerHeightWithMargin) : headerHeightWithMargin}px)`,
      margin: '0 32px',
    } : {
      position: 'relative',
      height: `calc(100vh - ${isLevel2Menu ? (subMenuHeight + headerHeightWithMargin) : headerHeightWithMargin}px)`,
      margin: '0 32px',
    } }
    >
      { authData && (
        <iframe
          ref={ iframeRef }
          title={ formattedIframeUrl }
          width='100%'
          src={ formattedIframeUrl }
          frameBorder='0'
          allow='clipboard-write'
          style={ {
            border: 0,
            paddingTop: 32,
            height: `calc(100vh - ${menus?.menu ? (subMenuHeight + headerHeightWithMargin) : headerHeightWithMargin}px)`,
            position: 'absolute',
            left: 0,
            top: 0,
          } }
          allowFullScreen
          onLoad={ () => {
            hide(true);
            // eslint-disable-next-line sonarjs/no-identical-functions
            setTimeout(() => {
              iframeEl?.current?.contentWindow?.postMessage(JSON.stringify({
                operation: 'CHANGE_LANGUAGE', language: i18n.language,
              }), '*');
            }, 0);
          } }
        />
      ) }
    </Box>
  );
});


export default DynamicIFrameRenderer;

