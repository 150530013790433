import {
  takeEvery,
  ForkEffect,
  call,
  put,
  CallEffect,
  PutEffect,
} from 'redux-saga/effects';
import { fetchUser, fetchUsers } from 'src/api';
import { UserModel } from 'src/models/user';
import { ApiResponse } from '@gauss/common-react-components/dist/types';
import { UserAction } from './types';
import { PayloadAction } from '../utils';
import {
  fetchUserSuccess,
  fetchUserError, fetchUsersSuccess, fetchUsersError,
} from './actions';
import { MainState } from '../reducers';
import { AuthState } from '../auth';

export const getToken = (state: MainState): AuthState => state.auth.authorization.data.accessToken;

function* fetchUserRequest(action: PayloadAction):
  Generator<CallEffect<ApiResponse<UserModel>> | PutEffect, void, ApiResponse<UserModel>> {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const userData: ApiResponse<UserModel> = yield call((payload) => fetchUser(), action.payload);
    if (userData.status) {
      yield put(fetchUserSuccess(userData.data!));
    } else {
      yield put(fetchUserError(new Error(userData.message)));
    }
  } catch (e: any) {
    yield put(fetchUserError(new Error(e.response?.error?.message)));
  }
}

function* fetchUsersRequest(action: PayloadAction):
  Generator<CallEffect<ApiResponse<UserModel[]>> | PutEffect, void, ApiResponse<UserModel[]>> {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const usersData: ApiResponse<UserModel[]> = yield call((payload) => fetchUsers(), action.payload);
    if (usersData.status) {
      yield put(fetchUsersSuccess(usersData.data!));
    } else {
      yield put(fetchUsersError(new Error(usersData.message)));
    }
  } catch (e: any) {
    yield put(fetchUsersError(new Error(e.response?.error?.message)));
  }
}

export default function* userSaga(): Generator<ForkEffect<never>, void> {
  yield takeEvery(UserAction.FETCH_USER_REQUEST, fetchUserRequest);
  yield takeEvery(UserAction.FETCH_USERS_REQUEST, fetchUsersRequest);
}
