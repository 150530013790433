export enum ResponseStateType {
  DEFAULT = 'DEFAULT',
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR'
}

export interface GeneralReduxModel {
  state: ResponseStateType;
  message?: string;
  data?: any;
  error?: Error;
  originalData?: any;

}
