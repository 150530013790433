import { lazy } from 'react';
import ChangePasswordPage from '@components/pages/changePassword';

const HomePage = lazy(() => import('@components/pages/adminUser/home'));
const AdministrationPage = lazy(() => import('@components/pages/adminUser/administration'));

/* Map route name converted toSnakeCase to actual components */
export const getComponentByPath = (path?: string) => {
  if (path?.includes('/admin-console/administration')) {
    return <AdministrationPage />;
  }
  if (path?.includes('/admin-console/home')) {
    return <HomePage />;
  }
  if (path?.includes('/admin-console/change-password')) {
    return <ChangePasswordPage />;
  }
  return null;
};

export default {};
