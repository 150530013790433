import { GeneralReduxModel } from '../types';
import { MenuModel } from '../../models/general';

export enum GeneralAction {
  FETCH_MENUS_REQUEST = 'FETCH_MENUS_REQUEST',
  FETCH_MENUS_SUCCESS = 'FETCH_MENUS_SUCCESS',
  FETCH_MENUS_ERROR = 'FETCH_MENUS_ERROR',

  SET_SELECTED_MENU = 'SET_SELECTED_MENU',

  FETCH_GOOGLE_LOGIN_CLIENT_ID_REQUEST = 'FETCH_GOOGLE_LOGIN_CLIENT_ID_REQUEST',
  FETCH_GOOGLE_LOGIN_CLIENT_ID_SUCCESS = 'FETCH_GOOGLE_LOGIN_CLIENT_ID_SUCCESS',
  FETCH_GOOGLE_LOGIN_CLIENT_ID_ERROR = 'FETCH_GOOGLE_LOGIN_CLIENT_ID_ERROR',

  SET_DIALOG = 'SET_DIALOG',
}
export interface GeneralReducerType extends GeneralReduxModel {
  data?: MenuModel[];
}
export interface GeneralReducerGoogleLoginClientIdType extends GeneralReduxModel {
  data?: string;
}
