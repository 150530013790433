import { FC, useEffect, useRef } from 'react';
import 'src/App.scss';
import { Route, Routes } from 'react-router-dom';
import LoginPage from '@components/pages/auth';
import PrivateRoutes from '@components/routes/privateRoutes';
import { PageWrapper } from '@gauss/common-react-components';
import mainEn from '@i18n/translations/en/main.json';
import mainGe from '@i18n/translations/ka/main.json';
import mainEs from '@i18n/translations/es/main.json';
import DialogComponent from '@components/UI/molecules/dialog/dialog.component';
import { pingStrategy } from '@api';
import axios from 'axios';
import { envVariables } from './env';
import useDocumentVisibility from './utils/useDocumentVisibilty';


const App: FC = () => {
  const documentState = useDocumentVisibility();
  const timerRef = useRef<any>(null);

  useEffect(() => {
    if (documentState === 'visible' && !timerRef.current) {
      (async () => {
        const {
          status, data,
        } = await pingStrategy();
        if (status && data) {
          Promise.all(data.servers.map((server) => axios.get(server)));
          timerRef.current = setInterval(() => {
            Promise.all(data.servers.map((server) => axios.get(server)));
          }, data.time_period * 1000);
        } else {
          clearInterval(timerRef.current);
          timerRef.current = null;
        }
      })();
    } else {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }
  }, [documentState]);

  return (
    <PageWrapper
      noPadding
      i18nConfig={ [{
        lang: 'en',
        namespace: 'main',
        localizationFile: mainEn,
      }, {
        lang: 'ka',
        namespace: 'main',
        localizationFile: mainGe,
      }, {
        lang: 'es',
        namespace: 'main',
        localizationFile: mainEs,
      }] }
      env={ envVariables.NODE_ENV }
    >
      <>
        <Routes>
          <Route path='/login' element={ <LoginPage /> } />
          <Route path='*' element={ <PrivateRoutes /> } />
        </Routes>
        <DialogComponent />
      </>
    </PageWrapper>
  );
};

export default App;

