import { Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { MainState } from '@redux/reducers';
import Button from '@mui/material/Button';
import { setDialog } from '@redux/general/actions';

const DialogComponent = () => {
  const {
    label, description, show,
  } = useSelector((state: MainState) => state.general.dialog);
  const dispatch = useDispatch();
  const close = () => dispatch(setDialog({
    show: false,
  }));
  return (
    <Dialog
      open={ show }
      onClose={ close }
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      { !!description && (
        <DialogContent>
          <DialogContentText>
            { description }
          </DialogContentText>
        </DialogContent>
      ) }
      <DialogActions>
        <Button size='small' color='primary' variant='text' onClick={ close } autoFocus>{ label }</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogComponent;
