/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-debugger */
import { List, Box, useMediaQuery } from '@mui/material';
import Typography from '@mui/material/Typography';
import {
  StyledCollapse,
  StyledDrawer,
  StyledListItem,
  StyledNestedListItem,
  StyledDoubleNestedListItem,
} from '@components/layout/adminUser/sidebar/sidebar.styles';
import { FC, Fragment, useState, MouseEvent } from 'react';
import { SidebarProps } from '@components/layout/adminUser/sidebar/sidebar.types';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SubMenuModel } from '@models/general';
import { logout } from '@redux/auth/actions';
import { getFirstSubMenuItem } from '@utils';
import SVG from 'react-inlinesvg';
import { useSelector, useDispatch } from 'react-redux';
import { MainState } from '@redux/reducers';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@mui/material/styles';

import { ReactComponent as ArrowDownList } from '@assets/icons/arrowDownList.svg';
import { ReactComponent as ArrowUpList } from '@assets/icons/arrowUpList.svg';
import { ReactComponent as Plus } from '@assets/icons/plus.svg';
import { ReactComponent as Minus } from '@assets/icons/minus.svg';
import { palette } from '@gauss/common-react-components';
import LanguagePicker from '@components/UI/atoms/languagePicker';


const activeMenuClass = 'active';

const SidebarComponent: FC<SidebarProps> = ({ routesData }) => {
  const [nestedOpen, setNestedOpen] = useState<string>();
  const [doubleNestedOpen, setDoubleNestedOpen] = useState<string>();
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const userData = useSelector((mState: MainState) => mState.user.user.data);
  const myTheme = useTheme();
  const isBreakpointMatch = useMediaQuery(myTheme.breakpoints.down('md'));


  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const navigateTo = (menu: SubMenuModel, menuLevel?: 'first'| 'second') => {
    if (nestedOpen && menuLevel === 'first') {
      if (doubleNestedOpen) {
        setDoubleNestedOpen(undefined);
        setNestedOpen(undefined);
      } else {
        setNestedOpen(undefined);
      }
    }
    if (doubleNestedOpen && menuLevel === 'second') {
      setDoubleNestedOpen(undefined);
    }
    navigate(`/${getFirstSubMenuItem(menu).slug}`);
  };

  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };


  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };


  const initialFirstNameLetter = userData?.email.split('.')[0][0].toUpperCase();
  const initialLastNameLetter = userData?.email.split('.')[1][0].toUpperCase();

  const logoutClickHandler = () => {
    dispatch(logout());
    navigate('/login', {
      replace: true,
    });
  };


  return (
    <StyledDrawer
      variant='permanent'
    >
      <List sx={ {
        mt: 9,
        mb: 0,
      } }
      >
        { routesData?.filter((item) => !item.hide)?.map((item) => (
          <Fragment key={ item.slug }>
            <StyledListItem
              key={ item.slug }
              className={ `${location.pathname.includes(item.slug) ? activeMenuClass : ''}` }
              sx={ {
                paddingLeft: 3,
              } }
              onClick={ () => navigateTo(item, 'first') }
            >
              <SVG
                src={ item.icon ? item.icon
                  : item.slug.includes('home') ? '/icons/home.svg '
                    : item.slug.includes('administration_console') ? '/icons/admin.svg'
                      : '/icons/default.svg' }
                height={ 20 }
              />
              <Typography
                variant='bodyLarge'
                fontWeight='500'
                color='grey.100'
                sx={ {
                  paddingLeft: 2,
                } }
              >
                { t(`routes.${item.slug}`, item.name) }
              </Typography>
              {
                item.menu
                  ? (
                    <Box
                      sx={ {
                        width: 24,
                        height: 24,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'absolute',
                        right: 10,
                      } }
                      onClick={ () => setNestedOpen((prev) => (prev === item.slug
                        ? undefined
                        : item.slug)) }
                    >
                      { nestedOpen === item.slug ? (
                        <ArrowUpList />
                      ) : (
                        <ArrowDownList />
                      ) }
                    </Box>
                  )
                  : null
              }
            </StyledListItem>
            { item.menu
              ? (
                <StyledCollapse in={ item.slug === nestedOpen } timeout='auto'>
                  <List
                    sx={ {
                      mt: 0,
                      mb: 0,
                      pt: 0,
                      pb: 0,
                    } }
                  >
                    { item.menu.filter((menuItem) => !menuItem.hide)?.map((menuItem) => (
                      <Fragment key={ menuItem.slug }>
                        <StyledNestedListItem
                          key={ menuItem.slug }
                          className={ `${location.pathname.includes(menuItem.slug) && !menuItem.menu ? activeMenuClass : ''}` }
                          sx={ {
                            paddingLeft: 8,
                          } }
                          onClick={ nestedOpen ? () => navigateTo(menuItem, 'second') : undefined }
                        >
                          <Typography
                            variant='bodyMedium'
                            fontWeight='500'
                            color={ location.pathname.includes(menuItem.slug) && !menuItem.menu
                              ? palette.gaussPrimary.gaussPink
                              : 'grey.100' }
                          >
                            { t(`routes.${menuItem.slug}`, menuItem.name) }
                          </Typography>
                          { menuItem.menu
                            ? (
                              <Box
                                sx={ {
                                  width: 24,
                                  height: 24,
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  position: 'absolute',
                                  right: 10,
                                } }
                                onClick={ () => setDoubleNestedOpen((prev) => (prev === menuItem.slug
                                  ? undefined
                                  : menuItem.slug)) }
                              >
                                { menuItem.slug === doubleNestedOpen ? (
                                  <Minus />
                                ) : (
                                  <Plus />
                                ) }
                              </Box>
                            )
                            : null }
                        </StyledNestedListItem>
                        { menuItem.menu
                          ? (
                            <StyledCollapse in={ menuItem.slug === doubleNestedOpen } timeout='auto'>
                              <List
                                sx={ {
                                  mt: 0,
                                  mb: 0,
                                  pt: 0,
                                  pb: 0,
                                } }
                              >
                                { menuItem.menu.filter((childItem) => !childItem.hide)?.map((childItem) => (
                                  <StyledDoubleNestedListItem
                                    key={ childItem.slug }
                                    className={ `${location.pathname.includes(childItem.slug) ? activeMenuClass : ''}` }
                                    sx={ {
                                      paddingLeft: 12,
                                    } }
                                    onClick={ doubleNestedOpen ? () => navigateTo(childItem) : undefined }
                                  >
                                    <Typography
                                      variant='bodyMedium'
                                      fontWeight='400'
                                      color={ location.pathname.includes(childItem.slug)
                                        ? palette.gaussPrimary.gaussPink
                                        : 'grey.100' }
                                    >
                                      { t(`routes.${childItem.slug}`, childItem.name) }
                                    </Typography>
                                  </StyledDoubleNestedListItem>
                                )) }
                              </List>
                            </StyledCollapse>
                          )
                          : null }
                      </Fragment>
                    )) }
                  </List>
                </StyledCollapse>
              )
              : null }
          </Fragment>
        )) }
        { isBreakpointMatch ? (
          <StyledListItem
            sx={ {
              position: 'fixed',
              height: 80,
              backgroundColor: 'grey.900',
              marginRight: 5,
              borderRight: `1px solid ${palette.grey[500]}`,
              bottom: 0,
              justifyContent: 'space-between',
            } }
          >
            <Box
              sx={ {
                minWidth: 100,
              } }
            >
              <Tooltip title={ t('common.open-settings').toString() }>
                <IconButton
                  onClick={ handleOpenUserMenu }
                  sx={ {
                    padding: 0,
                  } }
                >
                  <Avatar
                    sx={ {
                      height: 32,
                      width: 32,
                    } }
                  >
                    { userData?.email ? userData?.email[0].toUpperCase() : 'G' }
                  </Avatar>
                </IconButton>
              </Tooltip>
              <Menu
                sx={ {
                  mt: '45px',
                } }
                id='menu-appbar'
                anchorEl={ anchorElUser }
                anchorOrigin={ {
                  vertical: 'top',
                  horizontal: 'right',
                } }
                keepMounted
                transformOrigin={ {
                  vertical: 'top',
                  horizontal: 'right',
                } }
                open={ Boolean(anchorElUser) }
                onClose={ handleCloseUserMenu }
              >
                <MenuItem key='change-password' onClick={ () => navigate('/change-password') }>
                  <Typography textAlign='center'>{ t('common.change-password') }</Typography>
                </MenuItem>
                <MenuItem key='logout' onClick={ logoutClickHandler }>
                  <Typography textAlign='center'>{ t('common.logout') }</Typography>
                </MenuItem>
              </Menu>
              { userData && (
              <Typography
                variant='h5Medium'
                sx={ {
                  mr: 2,
                  ml: 2,
                  color: palette.gaussPrimary.gaussPink,
                } }
              >
                { initialFirstNameLetter }
                { '. ' }
                { initialLastNameLetter }
              </Typography>
              ) }
            </Box>
            <LanguagePicker bottom />
          </StyledListItem>
        ) : null }
      </List>
    </StyledDrawer>
  );
};

export default SidebarComponent;
