import { AnyAction } from 'redux';
import { googleLoginClientId } from '@constants';
import { IOkDialog, SubMenuModel } from '@models/general';
import { normalizeString } from '@utils';
import { GeneralAction, GeneralReducerGoogleLoginClientIdType, GeneralReducerType } from './types';
import { ResponseStateType } from '../types';

export type GeneralState = {
  menus: GeneralReducerType;
  selectedMenu?: SubMenuModel;
  dialog: IOkDialog;
  googleLoginClientId: GeneralReducerGoogleLoginClientIdType;
}

const INITIAL_STATE: GeneralState = {
  menus: {
    state: ResponseStateType.DEFAULT,
    message: '',
    data: [],
  },
  selectedMenu: undefined,
  dialog: {
    show: false,
  },
  googleLoginClientId: {
    state: ResponseStateType.DEFAULT,
    message: '',
    data: undefined,
  },
};

export default (state = INITIAL_STATE, action: AnyAction = {
  type: '',
}): GeneralState => {
  switch (action.type) {
    case GeneralAction.FETCH_MENUS_REQUEST:
      return {
        ...state,
        menus: {
          state: ResponseStateType.LOADING,
          message: '',
          data: undefined,
        },
      };
    case GeneralAction.FETCH_MENUS_SUCCESS:
      return {
        ...state,
        menus: {
          state: ResponseStateType.SUCCESS,
          message: '',
          data: action.payload,
        },
      };
    case GeneralAction.FETCH_MENUS_ERROR:
      return {
        ...state,
        menus: {
          state: ResponseStateType.ERROR,
          message: action.payload?.message,
          error: action.payload,
        },
      };
    case GeneralAction.SET_SELECTED_MENU:
      return {
        ...state,
        selectedMenu: action.payload,
      };
    case GeneralAction.FETCH_GOOGLE_LOGIN_CLIENT_ID_REQUEST:
      return {
        ...state,
        googleLoginClientId: {
          state: ResponseStateType.LOADING,
          message: '',
          data: undefined,
        },
      };
    case GeneralAction.FETCH_GOOGLE_LOGIN_CLIENT_ID_SUCCESS:
      return {
        ...state,
        googleLoginClientId: {
          state: ResponseStateType.SUCCESS,
          message: '',
          data: normalizeString(action.payload),
        },
      };
    case GeneralAction.FETCH_GOOGLE_LOGIN_CLIENT_ID_ERROR:
      return {
        ...state,
        googleLoginClientId: {
          state: ResponseStateType.ERROR,
          message: action.payload?.message,
          error: action.payload,
          data: googleLoginClientId, // Default value if it's not provided from bucket
        },
      };
    case GeneralAction.SET_DIALOG:
      return {
        ...state,
        dialog: action.payload,
      };
    default:
      return state;
  }
};
