import { FormEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { googleLoginRequest, loginRequest } from 'src/redux/auth/actions';
import LoadingButton from '@gauss/common-react-components/dist/components/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { TextField } from '@gauss/common-react-components';
import { CircularProgress, Grid } from '@mui/material';
import { useBackdrop, useOkDialog } from '@gauss/common-react-components/dist/context';
import palette from '@gauss/common-react-components/dist/themes/palette';
import Divider from '@gauss/common-react-components/dist/components/Divider';
import { useTranslation } from 'react-i18next';
import { fetchGoogleLoginClientIdRequest } from '@redux/general/actions';
import { MainState } from '@redux/reducers';
import { ResponseStateType } from '@redux/types';
import LoginHeroImage from '../../../../assets/images/login-hero.jpg';
import { ReactComponent as GaussLogo } from '../../../../assets/logos/gauss-logo.svg';
import { ReactComponent as AccountIcon } from '../../../../assets/icons/account-icon.svg';

const Auth = () => {
  const { showDialog } = useOkDialog();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    state,
    message,
  } = useSelector((appState: MainState) => appState.auth.authorization);
  const {
    state: googleLoginClientIdState, data: googleLoginClientId,
  } = useSelector((appState: MainState) => appState.general.googleLoginClientId);
  const [, forceUpdate] = useState({});
  const {
    i18n, t,
  } = useTranslation();

  useEffect(() => {
    if (i18n.isInitialized) {
      forceUpdate({});
    }
  }, [i18n.isInitialized]);

  const {
    show, hide,
  } = useBackdrop();

  useEffect(() => {
    if (state === ResponseStateType.LOADING) {
      show();
    } else {
      hide();
      if (state === ResponseStateType.SUCCESS) {
        navigate('/', {
          replace: true,
        });
      }
    }
  }, [state, message, navigate, show, hide]);

  useEffect(() => {
    if (state === ResponseStateType.ERROR) {
      showDialog({
        description: message,
      });
    }
  });

  useEffect(() => {
    dispatch(fetchGoogleLoginClientIdRequest());
  }, [dispatch]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email') as string;
    const password = data.get('password') as string;

    if (email && password) {
      dispatch(loginRequest({
        email,
        password,
      }));
    }
  };
  const responseGoogle = (response: { credential?: string; }) => {
    if (response?.credential) {
      dispatch(googleLoginRequest({
        idToken: response.credential,
      }));
    }
  };

  return (
    <Box
      sx={ {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      } }
    >
      <Box sx={ {
        width: 585,
        position: 'relative',
        display: {
          md: 'block',
          xs: 'none',
        },
      } }
      >
        <Box sx={ {
          position: 'absolute',
          background: palette.grey[900],
          opacity: 0.5,
          mixBlendMode: 'multiply',
          width: '100%',
          height: '100%',
        } }
        />
        <Box sx={ {
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          padding: 9,
          background: `url(${LoginHeroImage})`,
          backgroundSize: 'cover',
        } }
        >
          <Box sx={ {
            zIndex: 1,
          } }
          >
            <Box>
              <GaussLogo />
            </Box>
            <Box>
              <Typography
                variant='h1'
                color='base.white'
              >
                { t('common.welcome-to-gauss') }
              </Typography>
            </Box>
            <Box sx={ {
              mt: 3,
            } }
            >
              <Typography
                variant='bodyLarge'
                color='base.white'
              >
                { t('common.description') }
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={ {
          width: '100%',
          textAlign: 'center',
          position: 'absolute',
          left: 0,
          bottom: 24,
        } }
        >
          <Typography
            variant='labelSmall'
            color='base.white'
          >
            { t('common.copyright') }
          </Typography>
        </Box>
      </Box>
      <Box sx={ {
        flex: 1,
        maxWidth: 450,
        margin: '0 auto',
        padding: 3,
      } }
      >
        <Box sx={ {
          display: 'flex', alignItems: 'center', mb: 2,
        } }
        >
          <AccountIcon />
          <Typography
            variant='h3'
            color='grey.900'
            sx={ {
              ml: 1,
              mt: -1,
            } }
          >

            { t('login.login-account') }
          </Typography>
        </Box>
        <Typography variant='bodyLarge' color='grey.500'>
          { t('login.welcome-description') }
        </Typography>
        <Box
          component='form'
          onSubmit={ handleSubmit }
        >
          <Grid
            sx={ {
              mt: 2,
            } }
            container
            spacing={ 2 }
          >
            <Grid item xs={ 12 }>
              <TextField
                required
                placeholder={ t('login.email').toString() }
                name='email'
                type='email'
                autoFocus
              />
            </Grid>
            <Grid item xs={ 12 }>
              <TextField
                required
                placeholder={ t('login.password').toString() }
                type='password'
                name='password'
              />
            </Grid>
            <Grid item xs={ 12 }>
              <LoadingButton
                loading={ state === ResponseStateType.LOADING }
                type='submit'
                fullWidth
                variant='contained'
                color='primary'
                size='large'
              >
                { t('login.sign-in') }
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
        <Divider
          sx={ {
            mt: 4,
            mb: 3,
          } }
          textAlign='center'
        >
          <Typography variant='bodySmall' color='grey.500' fontWeight='400'>{ t('login.sign-up-with') }</Typography>
        </Divider>
        <Box sx={ {
          textAlign: 'center',
        } }
        >
          { googleLoginClientIdState === ResponseStateType.LOADING ? (
            <CircularProgress color='secondary' />
          ) : (googleLoginClientId && (
          <GoogleOAuthProvider clientId={ googleLoginClientId }>
            <Box display='flex' justifyContent='center'>
              <GoogleLogin
                onSuccess={ responseGoogle }
                onError={ () => {
                  console.log('Login Failed');
                } }
              />
            </Box>
          </GoogleOAuthProvider>
          )
          ) }
        </Box>
      </Box>
    </Box>
  );
};

export default Auth;
