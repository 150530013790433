import { Grid } from '@mui/material';
import { PageTitle, TextField, useOkDialog } from '@gauss/common-react-components';
import LoadingButton from '@gauss/common-react-components/dist/components/Button';
import Box from '@mui/material/Box';
import { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { changePassword } from '@api';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as DataQualityIcon } from '@assets/icons/data-quality-icon.svg';

export const ChangePassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const { showDialog } = useOkDialog();
  const navigate = useNavigate();
  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const currentPassword = data.get('currentPassword') as string;
    const newPassword = data.get('newPassword') as string;
    const newPasswordConfirm = data.get('newPasswordConfirm') as string;

    if (currentPassword && newPassword && newPasswordConfirm) {
      if (newPassword !== newPasswordConfirm) {
        showDialog({
          description: t('change-password.passwords-should-match').toString(),
          label: 'Ok',
        });
        return;
      }
      setIsLoading(true);
      const { status } = await changePassword({
        currentPassword,
        newPassword,
        newPasswordConfirm,
      });
      if (status) {
        navigate('/');
      }
      setIsLoading(false);
    }
  };

  return (
    <Box sx={ {
      padding: 4,
    } }
    >
      <Box sx={ {
        mt: 5,
      } }
      >
        <PageTitle icon={ <DataQualityIcon /> }>{ t('common.change-password') }</PageTitle>
      </Box>
      <Box
        component='form'
        onSubmit={ handleSubmit }
      >
        <Grid
          sx={ {
            mt: 2,
          } }
          container
          spacing={ 2 }
        >
          <Grid item xs={ 12 }>
            <TextField
              required
              placeholder={ t('change-password.current-password').toString() }
              name='currentPassword'
              type='password'
              autoFocus
            />
          </Grid>
          <Grid item xs={ 12 }>
            <TextField
              required
              placeholder={ t('change-password.new-password').toString() }
              type='password'
              name='newPassword'
            />
          </Grid>
          <Grid item xs={ 12 }>
            <TextField
              required
              placeholder={ t('change-password.confirm-password').toString() }
              type='password'
              name='newPasswordConfirm'
            />
          </Grid>
          <Grid item xs={ 12 }>
            <LoadingButton
              loading={ isLoading }
              type='submit'
              fullWidth
              variant='contained'
              color='primary'
              size='large'
            >
              { t('common.change-password') }
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ChangePassword;
