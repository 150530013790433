import { styled } from '@mui/system';
import { fontFamily, palette } from '@gauss/common-react-components';
import Box from '@mui/material/Box';

export const StyledSubMenu = styled(Box)(({ theme }) => ({
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  maxWidth: '300px',
  marginRight: '16px',
  marginLeft: 0,
  backgroundColor: palette.grey[100],
  border: `1px solid ${palette.grey[300]}`,
  borderTopLeftRadius: 4,
  borderTopRightRadius: 4,
  flexGrow: 0,
  cursor: 'pointer',
  color: palette.grey[400],
  fontFamily: fontFamily.medium,
  fontSize: 16,
  lineHeight: '100%',
  height: 44,

  '&:hover': {
    backgroundColor: palette.grey[200],
  },
  '&.active': {
    backgroundColor: palette.base.white,
    border: `1px solid ${palette.grey[100]}`,
    borderBottom: `3px solid ${palette.gaussPrimary.gaussPink}`,
    color: palette.grey[800],
    '& svg': {
      fill: palette.gaussPrimary.gaussPink,
      '& path': {
        fill: palette.gaussPrimary.gaussPink,
      },
    },
  },
  '&.disabled': {
    opacity: 0.7,
    cursor: 'not-allowed',
  },
  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(1),
    mb: theme.spacing(1),
    width: '100%',
  },
}));

export const SecondStyledSubMenu = styled(Box)(({ theme }) => ({
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  maxWidth: '200px',
  marginRight: '16px',
  marginLeft: 0,
  backgroundColor: palette.grey[100],
  borderRadius: 32,
  flexGrow: 0,
  cursor: 'pointer',
  color: palette.grey[400],
  fontFamily: fontFamily.medium,
  fontSize: 16,
  lineHeight: '100%',
  height: 44,

  '&:hover': {
    backgroundColor: palette.grey[200],
  },
  '&.active': {
    backgroundColor: palette.gaussPrimary.gaussPink,
    border: `1px solid ${palette.grey[100]}`,
    color: palette.grey[200],
    '& svg': {
      fill: palette.gaussPrimary.gaussPink,
      '& path': {
        fill: palette.gaussPrimary.gaussPink,
      },
    },
  },
  '&.disabled': {
    opacity: 0.7,
    cursor: 'not-allowed',
  },
  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(1),
    mb: theme.spacing(1),
    width: '100%',
  },
}));

export const StyledSubMenuList = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
  alignItems: 'center',
  alignContent: 'space-between',
}));
